
import { Timestamp } from 'firebase/firestore';
import { Modal } from 'react-bootstrap';
import HolidaysModel from '../../model/holidays.model';
import ApiServices from '../../services/api.service';
import React, { useState } from 'react';
import JSON_HOLIDAY from "../../assets/json/holiday-2024.json";
const AddHolidaysModal = ({ message, isShow }: any) => {

    const [show, setShow] = useState(isShow);
    const handleClose = () => setShow(false);


    const addHolidays = () => {
        let holidayMod : HolidaysModel[] = new Array();
        JSON_HOLIDAY.map((item) => {
            let HolidayItem : HolidaysModel = {
                date : Timestamp.fromDate(new Date(item.date)),
                isApprove: item.isApprove,
                title: item.title
            }
            holidayMod.push(HolidayItem);
        })
        console.log(holidayMod);

        holidayMod.map((item: any, index: number) => {
            ApiServices().saveHolidays(item).then((res: any) => {
                console.log(res);
            }
            )
        })
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add holidays</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message}

                    <button onClick={addHolidays}>Add Holidays</button>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default AddHolidaysModal;