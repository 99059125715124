
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./admin-search.scss";
import { initializeApp } from "firebase/app";
import config from "../../../config";
import { and, collection, endAt, getDocs, getFirestore, or, orderBy, query, startAt, where } from "firebase/firestore";
import tokenService from "../../../services/token.service";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Button, Form, FormControl, ProgressBar, Table } from "react-bootstrap";
import LeaveModel from "../../../model/leave.model";
import LeaveTable from "./leave-table";

const AdminSearchComponent = () => {
    const app = initializeApp(config.firebaseConfig);
    const auth = getAuth(app);
    const db = getFirestore(app);
    const users = tokenService.getUser();
    let navigate = useNavigate();
    const [uid, setUID] = useState('');
    const [key, setKey] = useState(0);
    const [value, setValue] = useState("");
    const [resultLeave, setResultLeave] = useState<LeaveModel[]>(new Array());
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        checkLogin();
    }, [])

    const checkLogin = () => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                if (user.email === "benz@scoutout.net" || user.email === "sunny@scoutout.net" || user.email === "tom@scoutout.net" || user.email === "thanut@scoutout.net") {
                    setUID(uid);
                } else {
                    navigate('/login?redirect_url=admin');
                }
            } else {
                navigate('/login?redirect_url=admin');
            }
        });
    }

    const onTextChange = async (value) => {
        setValue(value);
    }

    const queryByEmployeeName = async (resultLeaveMod) => {
        if (resultLeave) {
            const qEmployeeName = query(collection(db, "leave"),
                orderBy("employeename"), startAt(value), endAt(value + '\uf8ff')
            );
            const querySnapshot = await getDocs(qEmployeeName);
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                const dataRes = doc.data() as LeaveModel;
                dataRes.docId = doc.id;

                resultLeaveMod.push(dataRes);
            })
            console.log("End... queryByEmployeeName");
            queryByEmployeeNickName(resultLeaveMod);
        }


    }

    const queryByEmployeeNickName = async (resultLeaveMod) => {
        if (resultLeave) {
            const qEmployeeName = query(collection(db, "leave"),
                orderBy("employeenickname"), startAt(value), endAt(value + '\uf8ff')
            );
            const querySnapshot = await getDocs(qEmployeeName);
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                const dataRes = doc.data() as LeaveModel;
                dataRes.docId = doc.id;

                resultLeaveMod.push(dataRes);
            })
            console.log("End... queryByEmployeeNickName");
            queryByUserUid(resultLeaveMod)
        }


    }

    const queryByUserUid = async (resultLeaveMod) => {
        if (resultLeave) {
            const qEmployeeName = query(collection(db, "leave"),
                orderBy("useruid"), startAt(value), endAt(value + '\uf8ff')
            );
            const querySnapshot = await getDocs(qEmployeeName);
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                const dataRes = doc.data() as LeaveModel;
                dataRes.docId = doc.id;

                resultLeaveMod.push(dataRes);
            })
            console.log("End... queryByEmployeeUid");
            console.log(resultLeaveMod);
            cleaningData(resultLeaveMod);
        }
    }

    const cleaningData = async (resultLeaveMod) => {
        const resultMod = resultLeaveMod as LeaveModel[]
        const resultLeave: LeaveModel[] = new Array();
        const seenIds = {};
        for (const item of resultMod) {
            if (item.docId) {
                if (!seenIds[item.docId]) {
                    seenIds[item.docId] = true;
                    resultLeave.push(item);
                }
            }

        }
        const resultM = resultLeave.sort((a, b) => Number(b.datecreate) - Number(a.datecreate));
        setResultLeave(resultM);
        setLoading(false);


    }

    const clickSearch = async () => {
        if (resultLeave) {
            setLoading(true);
            console.log(value);
            const resultLeaveMod = new Array();
            queryByEmployeeName(resultLeaveMod);
        }


    }


    return (

        <>
            <div className="admin-search-main">
                <h1 className="admin-search-header">ระบบค้นหาการลา</h1>
                <div className="admin-search-input-control">
                    <div className="admin-search-input-item">
                        <span className="admin-search-input-title">ค้นหา</span>
                        <Form.Control
                            type="text"
                            className="admin-search-input-form"
                            onChange={(e) => onTextChange(e.target.value)}
                        />

                    </div>
                    <Button className="admin-search-button" onClick={clickSearch}>Search</Button>
                </div>
                {
                    isLoading &&

                    <div className="admin-search-loading-control">
                       <span className="admin-search-loading">Loading . . . </span>
                    </div>
                }
                {
                    resultLeave && !isLoading &&
                    <div className="admin-search-result-control">
                        <span>ผลการค้นหาทั้งหมด: {resultLeave.length}</span>
                        <LeaveTable data={resultLeave} ></LeaveTable>
                    </div>
                }

            </div>

        </>
    )
}

export default AdminSearchComponent;
