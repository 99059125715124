import "./admin-rejected.scss";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import configLanguage from "../../../config-language";
import LeaveModel from "../../../model/leave.model";
import React, { useState } from 'react';
import config from "../../../config";
import EMPTY_IMG from "../../../assets/images/empty.png";
import { Tab, Tabs } from "react-bootstrap";

const AdminRejectedComponent = ({ dbAccess }: any) => {
    const [totalAp, setTotalAp] = useState(0);
    const [apList, setApList] = useState<LeaveModel[]>();
    const db: any = dbAccess;
    const getAwaitingList = async () => {
        const q = query(collection(db, "leave"), where("statusleave", "==", configLanguage.rejected),
            where("dateleavefrom", ">=", new Date("01/01/" + config.currentYear)),
            orderBy("dateleavefrom", "desc"));
        const querySnapshot = await getDocs(q);
        const leavetd: LeaveModel[] = [];
        querySnapshot.forEach((doc) => {
            const dataRes = doc.data() as LeaveModel;
            leavetd.push(dataRes);
        });
        setTotalAp(leavetd.length);
        setApList(leavetd)
    }
    React.useEffect(() => {
        getAwaitingList();

    }, [])
    return (
        <>
            <div className="admin-rejected">
                {
                    apList?.length === 0 ?

                        <div className="d-flex flex-column">
                            <img src={EMPTY_IMG} className="not-found-img mx-auto"></img>
                            <span className="not-found-text">ไม่มีรายการ</span>
                        </div>
                        :

                        <div className="d-flex flex-column">

                            <div className="info-control">
                                <div className="info-row" >
                                    <span className="text-desc-bold my-auto">No.</span>
                                    <span className="text-desc-bold my-auto">Name</span>
                                    <span className="text-desc-bold my-auto mx-auto">Leave Date</span>
                                    <span className="text-desc-bold my-auto mx-auto">Time</span>
                                </div>
                            </div>
                            <div className="info-control">
                                {
                                    apList?.map((item: LeaveModel, index: number) => {

                                        return (
                                            <div className="info-row" key={index}>
                                                <span className="text-desc my-auto">{index + 1}</span>
                                                <div className="detail-control  my-auto">
                                                    <span className="text-desc-bold">
                                                        {item.employeenickname}
                                                    </span>
                                                    <span className="text-small">(
                                                        {
                                                            item.employeename
                                                        })
                                                    </span>
                                                </div>
                                                <span className="text-desc text-center">
                                                    {item.dateleavefrom.toDate().toLocaleDateString()} <br></br> - <br></br> {item.dateleaveto.toDate().toLocaleDateString()}
                                                </span>
                                                <span className="text-desc text-center">
                                                    {item.leavetime}
                                                </span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>



                }

            </div>
        </>
    )


}

export default AdminRejectedComponent;