import { initializeApp } from "firebase/app";
import config from "../../config";
import { getAuth, signOut } from "firebase/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const LogOutComponent = () => {

    const app = initializeApp(config.firebaseConfig);
    const auth = getAuth(app);
    const navigate = useNavigate();

    useEffect(() => {

        signOut(auth);
        navigate('/')
    },[])

    return (
        <></>
    )
}

export default LogOutComponent