
import { Timestamp } from 'firebase/firestore';
import { Modal } from 'react-bootstrap';
import HolidaysModel from '../../model/holidays.model';
import ApiServices from '../../services/api.service';
import React, { useState } from 'react';
import SimilarPersonalityModel from '../../model/similar-personality.model';
import ProfileModel from '../../model/profile.model';
import './similar-personality.scss';
const SimilarPersonalityModal = ({ showSimilarPersonality, similarList, handleCloseSimilarPersonality }: any) => {

    const similarPers: ProfileModel[] = similarList



    return (
        <>
            <Modal
                show={showSimilarPersonality}
                onHide={handleCloseSimilarPersonality}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ranking Similar Personality</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='similar-pers'>
                        {
                            similarPers.map((item: ProfileModel, index: number) => {

                                return (
                                    <div className="similar-card-control" key={index}>
                                        <img src={item.photoURL} referrerPolicy="no-referrer" className='similar-profile-img'></img>
                                        <div className='d-flex flex-column similar-name-control'>
                                            <span className='similar-name'>{item.displayName}</span>
                                            <span className='similar-team-bold'>Team
                                                <span className='similar-team-normal'>
                                                    {item.team}</span></span>
                                        </div>

                                        <div className='similar-rank-control my-auto'>
                                            <span className='similar-rank-header'>Ranking</span>
                                            <span className='similar-rank-text mx-auto'>{index+1}</span>
                                            <span className='similar-rank-percent mx-auto'>{item.rankingMBTI}%</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default SimilarPersonalityModal;