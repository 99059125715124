import { Nav, NavItem } from "react-bootstrap";
import EMP_IMG from "../../assets/images/profile.png";
import EMP_ACTIVE_IMG from "../../assets/images/profile-active.png";
import AWAITING_IMG from "../../assets/images/dashboard.png";
import AWAITING_ACTIVE_IMG from "../../assets/images/dashboard-active.png";
import React, { useState } from 'react';
import "./navigation.scss";
import { ProfileComponent } from "./profile/profile";
import DashboardComponent from "./dashboard";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { collection, doc, getDoc, getDocs, getFirestore, orderBy, query, where } from "firebase/firestore";
import ProfileModel from "../../model/profile.model";
import config from "../../config";
import { initializeApp } from "firebase/app";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGlobalState } from "../login/login";
import { useGoogleLogin } from '@caranmegil/react-google-login';
const NavigationComponent = () => {
    const clientId = config.clientId;
    const ggAuth = useGlobalState('ggAuth');
    const app = initializeApp(config.firebaseConfig);
    const [uid, setUID] = React.useState('');
    const [key, setKey] = useState(0);

    const auth = getAuth(app);
    let navigate = useNavigate();
    const db = getFirestore(app);

    const [profileRes, setProfileRes] = React.useState<ProfileModel>();
    const tabs = [{
        route: "/dashboard",
        icon: AWAITING_IMG,
        icon_active: AWAITING_ACTIVE_IMG,
        label: "Dashboard"
    }, {
        route: "/profile",
        icon: EMP_IMG,
        icon_active: EMP_ACTIVE_IMG,
        label: "Profile"
    }]


    const getUsersData = async (uid: any) => {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const dataRes = docSnap.data() as ProfileModel;
            setProfileRes(dataRes);
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

    }


    const checkLogin = async () => {
        try {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    const uid = user.uid;
                    const q = query(collection(db, "users"), where("userUID", "==", uid));
                    getDocs(q).then(docs => {
                        docs.forEach((doc) => {
                            // doc.data() is never undefined for query doc snapshots
                            setUID(doc.id);
                            console.log(doc.id);
                            getUsersData(doc.id);
                        });

                    });

                    // getUsersLeaveToday();
                    // getMeLeave(uid);
                    // getMeLeaveHistory(uid);
                } else {
                    navigate("/login");
                    signOut(auth);
                }
            });
        } catch {
            navigate("/login");
            signOut(auth);
        }

    }
    const onTabChange = (key: any) => {
        setKey(key);
    }

    React.useEffect(() => {

        checkLogin();

    }, [])


    return (<>
        <div className="navigation-main ">
            <div className="container-control">
                <div className="d-flex flex-column w-100">

                    <div className="content-control">
                        {
                            profileRes && <ProfileComponent dbAccess={db} profile={profileRes} uid={uid}> </ProfileComponent>
                        }

                        {/* {
                        profileRes ?
                            key === 0 ?
                                <DashboardComponent listEmp={listEmp}></DashboardComponent> :
                                <ProfileComponent dbAccess={db} profile={profileRes} uid={uid}> </ProfileComponent> : null
                    } */}
                    </div>
                    {/* Bottom Tab Navigator*/}
                    {/* {
                    profileRes ?
                        <div className="nav-grid">
                            {
                                tabs.map((tab, index: number) => (

                                    index == tabs.length - 1 ?
                                        <NavItem key={`tab-${index}`} onClick={() => onTabChange(index)} className="items-control-no-line">
                                            <div className="nav-link bottom-nav-link my-auto">
                                                <div className="row d-flex flex-column justify-content-center align-items-center">
                                                    {
                                                        index === key ?

                                                            <img src={tab.icon_active} className="tab-icon-img"></img> : <img src={tab.icon} className="tab-icon-img"></img>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </NavItem> :
                                        <NavItem key={`tab-${index}`} onClick={() => onTabChange(index)} className="items-control">
                                            <div className="nav-link bottom-nav-link my-auto">
                                                <div className="row d-flex flex-column justify-content-center align-items-center">
                                                    {
                                                        index === key ?

                                                            <img src={tab.icon_active} className="tab-icon-img"></img> : <img src={tab.icon} className="tab-icon-img"></img>
                                                    }
                                                 
                                                </div>
                                            </div>
                                        </NavItem>

                                ))
                            }
                        </div>
                        : null
                } */}

                </div>
            </div>
        </div>
    </>)
}

export default NavigationComponent;