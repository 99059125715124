
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from "firebase/auth";
import { collection, doc, getDoc, getDocs, getFirestore, orderBy, query, Timestamp, where } from "firebase/firestore";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import config from "../../config";
import LeaveModel from "../../model/leave.model";
import ProfileModel from "../../model/profile.model";
import "./dashboard.scss";
import configLanguage from "../../config-language";
import ApiServices from "../../services/api.service";
import CourseModel from "../../model/course.model";
import Carousel from "react-multi-carousel";
const DashboardComponent = () => {
    const [listEmp, setListEmp] = useState<ProfileModel[]>();
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 4 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 150 },
            items: 2,
            slidesToSlide: 1// optional, default to 1.
        }
    };
    const [filterCourse, setFilterCourrse] = useState("All");
    const app = initializeApp(config.firebaseConfig);
    const auth = getAuth(app);
    const db = getFirestore(app);
    const [uid, setUID] = React.useState('');
    const [isLogin, setLogin] = React.useState(false);
    const [profileRes, setProfileRes] = React.useState<ProfileModel>();
    const [course, setCourse] = useState<CourseModel[]>();
    let navigate = useNavigate();
    React.useEffect(() => {
   
        checkLogin();
    }, [])

    const checkLogin = () => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                setUID(uid);
                getUsersData(uid);
            } else {
                navigate('/login');
            }
        });
    }

    const getUsersData = async (uid: any) => {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const dataRes = docSnap.data() as ProfileModel;
            setProfileRes(dataRes);
            getCourseList(dataRes, "All")
        } else {
            // doc.data() will be undefined in this case
        }


    }

    const clickFilterCourse = (filter: string) => {
        setFilterCourrse(filter)
        getCourseList(profileRes, filter);
    }


    const getCourseList = (profile, filter) => {
        let positions = "";
        profile?.position.map((item: string, index: number) => {
            if (index === profile.position.length - 1) {
                positions += item
            } else {
                positions += item + ","
            }
        })

        if (filter === "All") {
            ApiServices().getCourses(positions, 7).then((res: CourseModel[]) => {
                setCourse(res);
            })
        } else {
            ApiServices().getCourses(filter, 7).then((res: CourseModel[]) => {
                setCourse(res);
            })
        }



    }


   

    function search(nameKey: any, myArray: ProfileModel[]) {
        for (let i = 0; i < myArray.length; i++) {
            if (myArray[i].uid === nameKey) {
                return myArray[i];
            }
        }
    }

    const findProfile = (uid: any) => {
        if(listEmp){

            const resultObject = search(uid, listEmp)
            return resultObject
        }else{
            return null;
        }
   

    }

    const clickCourse = (url: string) => {

        window.open(url, "_blank");

    }
  
    return (
        <>

            <div className="dashboard">


                        <div className="dashboard-control">
                            <div className="d-flex flex-column">
                                {
                                    course ?

                                        <div className="course-control">
                                            <span className="course-text-title">Growth your path</span>
                                            <div className="filter-course-control">
                                                {
                                                    filterCourse === "All" ?
                                                        <button className="filter-course-item filter-course-active" onClick={() => clickFilterCourse("All")}>All</button> :
                                                        <button className="filter-course-item" onClick={() => clickFilterCourse("All")}>All</button>
                                                }
                                                {
                                                    profileRes &&
                                                    profileRes.position.map((item: string, index: any) => {

                                                        return (
                                                            filterCourse === item ?

                                                                <button className="filter-course-item filter-course-active" key={index} onClick={() => clickFilterCourse(item)}>{item}</button> :
                                                                <button className="filter-course-item" key={index} onClick={() => clickFilterCourse(item)}>{item}</button>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="course-rec-control">
                                               
                                                <Carousel
                                                    responsive={responsive}
                                                    dotListClass="custom-dot-list-style"
                                                    itemClass="carousel-item-padding-40-px"
                                                    containerClass="carousel-container"
                                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                                >
                                                    {
                                                        course.map((item: CourseModel, index: number) => {
                                                            return (
                                                                <div key={index}>
                                                                    <div className="course-item"   onClick={() => clickCourse(item.url)}>
                                                                        <img src={item.image} className="course-img"></img>
                                                                        <div className="course-content-control">
                                                                            <span className="course-name">{item.title}</span>
                                                                            <span className="course-desc">{item.description}</span>
                                                                            <span className="course-recom-title">recommend for:

                                                                                <span className="course-recom-desc">{item.role}</span> </span>
                                                                        </div>
                                                                    </div>
                                                              </div>
                                                            )
                                                        })
                                                    }
                                                    <>
                                                        <div className="course-item"  >
                                                            <div className="course-view-all-control">
                                                                <span className="course-view-all">View All</span>
                                                                <span className="material-symbols-outlined course-view-all-icon my-auto">
                                                                    arrow_circle_right
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                </Carousel>
                                            </div>

                                        </div> : null
                                }

                            </div>

                        </div>
                    </div>

        </>
    )
}

export default DashboardComponent;



