import "./admin-employees.scss";
import { collection, getDocs, orderBy, query, Timestamp, where } from "firebase/firestore";
import React, { useState } from 'react';
import config from "../../../config";
import EMPTY_IMG from "../../../assets/images/empty.png";
import { Modal, Tab, Tabs } from "react-bootstrap";
import ProfileModel from "../../../model/profile.model";
import EmployeesDetailComponent from "./employees-detail/employees-detail";
import ApiServices from "../../../services/api.service";
import moment from 'moment';
const AdminEmployeesComponent = ({ dbAccess }: any) => {
    const [totalEmp, setTotalEmp] = useState(0);
    const [empList, setEmpList] = useState<ProfileModel[]>();
    const db: any = dbAccess;
    const [empId, setEmpId] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const getAllProfile = async () => {
        const q = query(collection(db, "users"),
            orderBy("probationDate", "asc")
        );
        const querySnapshot = await getDocs(q);
        const leavetd: ProfileModel[] = [];
        querySnapshot.forEach((doc) => {
            const dataRes = doc.data() as ProfileModel;
            // console.log(dataRes);
            leavetd.push(dataRes);
        });
        setTotalEmp(leavetd.length);
        setEmpList(leavetd)
        // console.log(leavetd);
    }
    React.useEffect(() => {
        getAllProfile();

    }, [])

    const calExp = (probDate: Timestamp, id: string, leave_percent: number, status: string, name: string) => {
        const proDate = new Date(probDate.toDate()) as Date;
        const dateCurrent = new Date;
        const endDate = new Date();
        const currentDateDif = moment(dateCurrent);
        const prorateDateDif = moment(proDate);

        endDate.setMonth(11);
        endDate.setFullYear(2023);
        endDate.setDate(31);
        endDate.setHours(0);
        endDate.setMinutes(0);
        endDate.setSeconds(0)
        const endDateDiff = moment(endDate);
        const yearPro = currentDateDif.diff(prorateDateDif, 'year');
        prorateDateDif.add(yearPro, 'years');
        const months = currentDateDif.diff(prorateDateDif, 'months');
        prorateDateDif.add(months, 'months');
        const days = currentDateDif.diff(prorateDateDif, 'days');
        prorateDateDif.add(days, 'days');

        const monthsEnd = currentDateDif.diff(endDateDiff, 'months');

        const showYears = yearPro + 0;
        const showMonth = months + 0;
        const showDays = days + 0;
        // console.log(showYears + " Years " + showMonth + " Months " + showDays + " Day");

        // if(id === "6SspkdBbLoQMnjvyfqJMcZgeM0b2"){
        //     ApiServices().updatePersonalLeave(id, config.personalLeave + showYears)
        // }
        console.log(name)
        if (showYears <= 0) {
            const totalMonth = monthsEnd * 1;
            const leaveTotal = Math.abs(totalMonth) * 1.25;
         
            if (status == "Contract") {
                console.log(Math.ceil(4), name);
                ApiServices().updatePersonalLeave(id, 4)
            } else if (leaveTotal == 0 || status == "Probation Approved") {
                console.log(Math.ceil(15), name);
                ApiServices().updatePersonalLeave(id, 15)
            } else if (status !== "Probation Approved" && status != "Contract") {
                console.log(Math.ceil(leaveTotal), name);
                ApiServices().updatePersonalLeave(id, Math.ceil(leaveTotal))
            }


        } else {
            if (leave_percent == 100) {

                let newTotal = config.personalLeave + showYears;
                if (newTotal > 20) {
                    newTotal = 20;
                }
                console.log(Math.ceil(newTotal), name);
                ApiServices().updatePersonalLeave(id, newTotal)
            } else {
                let newTotal = config.personalLeave + showYears;
                if (newTotal > 20) {
                    newTotal = 20;
                }
                let percentTotal = 100 - leave_percent;
                var val = calculateResult(newTotal, percentTotal)
                const totalLeave = parseFloat(val + "").toFixed(0);
                console.log(totalLeave, name);
                ApiServices().updatePersonalLeave(id, Number(totalLeave))
            }

        }


        // ApiServices().resetAllEmployees(id)
        return showYears + " Years " + showMonth + " Months " + showDays + " Day";

    }
    const calculateResult = (total, percentage) => {
        // Calculate the amount to subtract
        const amountToSubtract = (total * percentage) / 100;

        // Subtract the percentage
        let result = total - amountToSubtract;

        // Check if there is a decimal part
        if (result % 1 !== 0) {
            // If there is a decimal part, add it to the result
            result += result % 1;
        }

        return result;
    };
    const clickEmp = (id: any) => {
        setShow(true);
        setEmpId(id);
    }
    return (
        <>
            <div className="admin-employees">
                <h1 className="text-header">All Employees ({empList?.filter(
                    (fl: ProfileModel) => fl.employeeStatus !== "Out"
                ).length})</h1>
                <div className="info-control">

                    {
                        empList?.filter(
                            (fl: ProfileModel) => fl.employeeStatus !== "Out"
                        ).map((item: ProfileModel, index: number) => {

                            return (
                                <div className="card-control" key={index} onClick={() => clickEmp(item.uid)}>
                                    <div className="grid-control">
                                        <img src={item.photoURL} loading="lazy" className="profile-img" referrerPolicy="no-referrer"></img>
                                        <div className="d-flex flex-column">
                                            <span className="name-text">
                                                {item.employeenickname}
                                            </span>
                                            <span className="desc-text">
                                                {item.displayName}
                                            </span>
                                            <span className="position-text">
                                                Team:

                                                <span className="value-text">{item.team}</span>
                                            </span>
                                            <span className="position-text">
                                                Position:

                                                {
                                                    item.position !== undefined &&
                                                    item?.position.map((item2: any, index2: number) => {
                                                        return (
                                                            <span className="value-text" key={index2}>
                                                                {item2}
                                                                {

                                                                    (item.position.length == index2 + 1) ?
                                                                        "" : ","
                                                                }

                                                            </span>
                                                        )
                                                    })
                                                }
                                            </span>
                                            <span className="position-text">
                                                Experience : <span className="value-text">
                                                    {
                                                        item.leave_percent && calExp(item.startDate, item.uid, item.leave_percent, item.employeeStatus, item.displayName)
                                                    }
                                                </span>

                                            </span>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }

                </div>

                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EmployeesDetailComponent dbAccess={db} uid={empId} ></EmployeesDetailComponent>
                    </Modal.Body>

                </Modal>
            </div>
        </>
    )


}

export default AdminEmployeesComponent;