import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { NavLink, useNavigate } from "react-router-dom";
import config from "../../../config";
import React, { useState } from 'react';
import "./admin-dashboard.scss";
import { Nav, NavItem, Tab, Tabs } from "react-bootstrap";
import AdminAwaitingComponent from "../admin-awaiting/admin-awaiting";
import APPROVED_IMG from "../../../assets/images/approved.png";
import APPROVED_ACTIVE_IMG from "../../../assets/images/approved-active.png";
import HOLIDAY_IMG from "../../../assets/images/holiday.png";
import HOLIDAY_ACTIVE_IMG from "../../../assets/images/holiday-active.png";
import REJECTED_IMG from "../../../assets/images/rejected.png";
import REJECTED_ACTIVE_IMG from "../../../assets/images/rejected-active.png";
import AWAITING_IMG from "../../../assets/images/awaiting.png";
import AWAITING_ACTIVE_IMG from "../../../assets/images/awaiting-active.png";
import EMP_IMG from "../../../assets/images/emp.png";
import EMP_ACTIVE_IMG from "../../../assets/images/emp-active.png";
import AdminApprovedComponent from "../admin-approved/admin-approved";
import AdminRejectedComponent from "../admin-rejected/admin-rejected";
import AdminEmployeesComponent from "../admin-employees/admin-employees";
import AdminLeaveComponent from "../admin-leave/admin-leave";

import ApiServices from "../../../services/api.service";
import LeaveModel from "../../../model/leave.model";
import configLanguage from "../../../config-language";
import tokenService from "../../../services/token.service";
import Moment from "react-moment";
import moment from "moment";
const AdminDashboardComponent = () => {
    const app = initializeApp(config.firebaseConfig);
    const auth = getAuth(app);
    const db = getFirestore(app);
    const users = tokenService.getUser();
    let navigate = useNavigate();
    const [uid, setUID] = React.useState('');
    const [key, setKey] = useState(0);
    const tabs = [{
        route: "/admin/leave",
        icon: HOLIDAY_IMG,
        icon_active: HOLIDAY_ACTIVE_IMG,
        label: "Leave"
    }, {
        route: "/admin/employees",
        icon: EMP_IMG,
        icon_active: EMP_ACTIVE_IMG,
        label: "Employees"
    }]

    const checkLogin = () => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                if (user.email === "benz@scoutout.net" || user.email === "tom@scoutout.net" || user.email === "thanut@scoutout.net") {
                    setUID(uid);
                    addEvent2();
                } else {
                    navigate('/login?redirect_url=admin');
                }
            } else {
                navigate('/login?redirect_url=admin');
            }
        });
    }
    const addEvent2 = async () => {
        const q = query(collection(db, "leave"), where("eventId", "==", "null"), where("statusleave", "!=", "Canceled"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            const dataRes = doc.data() as LeaveModel;
            dataRes.docId = doc.id;
            const auth = getAuth();
            if (auth && dataRes) {
                let calendarId;
                let timeLeaveStr;
                let leaveSubmit = dataRes;
                if (dataRes.leavetype === configLanguage.personal_leave) {
                    if (dataRes.leavetime === configLanguage.full_day) {
                        timeLeaveStr = "เต็มวัน"
                        leaveSubmit.leavetime = configLanguage.full_day;
                        calendarId = config.calendarEmployeeleaveId
                    } else if (dataRes.leavetime === configLanguage.halfday_am) {
                        timeLeaveStr = "ครึ่งวัน (เช้า)"
                        leaveSubmit.leavetime = configLanguage.halfday_am;
                        calendarId = config.calendarHalfleaveId
                    } else {
                        timeLeaveStr = "ครึ่งวัน (บ่าย)"
                        leaveSubmit.leavetime = configLanguage.halfday_pm;
                        calendarId = config.calendarHalfleaveId
                    }
                } else {
                    if (dataRes.leavetime === configLanguage.full_day) {
                        timeLeaveStr = "เต็มวัน"
                        leaveSubmit.leavetime = configLanguage.full_day;
                        calendarId = config.calendarSickleaveId
                    } else if (dataRes.leavetime === configLanguage.halfday_am) {
                        timeLeaveStr = "ครึ่งวัน (เช้า)"
                        leaveSubmit.leavetime = configLanguage.halfday_am;
                        calendarId = config.calendarSickleaveId
                    } else {
                        timeLeaveStr = "ครึ่งวัน (บ่าย)"
                        leaveSubmit.leavetime = configLanguage.halfday_pm;
                        calendarId = config.calendarSickleaveId
                    }
                }
                const eventNow = {
                    'summary': dataRes.employeename + ' ' + dataRes.leavetype,
                    'description':
                        "<b>รายละเอียด</b>" + "<ul><li>เวลาที่ลา : " + timeLeaveStr + " </li>" + "<li>เหตุผลในการลา : " + dataRes.des + "</li></ul>",
                    'start': {
                        'date': moment(dataRes.dateleavefrom.toDate()).format("YYYY-MM-DD"),
                        'timeZone': 'Asia/Bangkok',
                    },
                    'end': {
                        'date': moment(dataRes.dateleaveto.toDate()).add(1,'days').format("YYYY-MM-DD"),
                        'timeZone': 'Asia/Bangkok',
                    }

                }


                try {

                    fetch(config.api.google_apis + calendarId + "/events", {
                        method: "POST",
                        headers: {
                            'Authorization': 'Bearer ' + users.accessToken
                        },
                        body: JSON.stringify(eventNow)
                    }).then((data) => {
                        return data.json();
                    }).then((data) => {
                        if (data.id) {
                            leaveSubmit.eventId = data.id;
                            ApiServices().updateSyncLeaveCalendar(dataRes.docId, leaveSubmit).then((res: any) => {
                            })
                        } else {
                           
                        }
                    })
                  
                } catch {


                }



            } else {
                navigate('/login')
            }

        });


    };
    const onTabChange = (key: any) => {
        setKey(key);
    }

    React.useEffect(() => {
        checkLogin();
    }, [])


    return (
        <>
            <div className="admin-dashboard">
                <div className="d-flex flex-column container-control">
                    <div className="container content-control">
                        {
                            key === 0 ?
                                <AdminLeaveComponent ></AdminLeaveComponent> :

                                <AdminEmployeesComponent dbAccess={db}></AdminEmployeesComponent>
                        }
                    </div>

                    {/* Bottom Tab Navigator*/}
                    <nav className="navbar navbar-light bottom-tab-nav" role="navigation">
                        <Nav className="w-100 h-100">
                            <div className="nav-grid">
                                {
                                    tabs.map((tab, index: number) => (
                                        <NavItem key={`tab-${index}`} onClick={() => onTabChange(index)} className="items-control">
                                            <div className="nav-link bottom-nav-link my-auto">
                                                <div className="row d-flex flex-column justify-content-center align-items-center">
                                                    {/* <FontAwesomeIcon size="lg" icon={tab.icon} /> */}
                                                    {
                                                        index === key ?

                                                            <img src={tab.icon_active} className="tab-icon-img"></img> : <img src={tab.icon} className="tab-icon-img"></img>
                                                    }
                                                    <div className={
                                                        index === key ?
                                                            "bottom-tab-label-active" : "bottom-tab-label"
                                                    }>{tab.label}</div>
                                                </div>
                                            </div>
                                        </NavItem>
                                    ))
                                }
                            </div>
                        </Nav>
                    </nav>
                </div>

            </div>
        </>
    )
}

export default AdminDashboardComponent;