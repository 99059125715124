import React, { useState } from 'react';
import { Table, Pagination } from 'react-bootstrap';
import LeaveModel from '../../../model/leave.model';

const LeaveTable = ({ data }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const pageNeighbours = 1; // Number of pages to show around the current page
  
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const totalPages = Math.ceil(data.length / itemsPerPage);
    // Change page

    const renderPageNumbers = () => {
        let pages: any = [];
    
        for (let i = 1; i <= totalPages; i++) {
          if (i === 1 || i === totalPages || i >= currentPage - pageNeighbours && i <= currentPage + pageNeighbours) {
            pages.push(
              <Pagination.Item key={i} active={i === currentPage} onClick={() => paginate(i)}>
                {i}
              </Pagination.Item>
            );
          } else if (i === currentPage - pageNeighbours - 1 || i === currentPage + pageNeighbours + 1) {
            pages.push(<Pagination.Ellipsis key={i} />);
          }
        }
    
        return pages;
      };

    return (
        <>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        {/* Add table headers based on your JSON keys */}
                        <th>User ID</th>
                        <th>Employee Name</th>
                        <th>Nick Name</th>
                        <th>Description</th>
                        <th>Leave date</th>
                        <th>Total Leave</th>
                        {/* ... other headers */}
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item:LeaveModel) => (
                        <tr key={item.docId}>
                            {/* Render table data here */}
                            <td>{item.useruid}</td>
                            <td>{item.employeename}</td>
                            <td>{item.employeenickname}</td>
                            <td>{item.des}</td>
                            <td>{item.dateleavefrom.toDate().toDateString()}</td>
                            <td>{item.leavedaytotal}</td>
                            {/* ... other data cells */}
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Pagination>
        {renderPageNumbers()}
      </Pagination>
        </>
    );
};

export default LeaveTable;
