import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import configLanguage from "../../../config-language";
import LeaveModel from "../../../model/leave.model";
import React, { useState } from 'react';
import config from "../../../config";
import "./admin-approved.scss";
import { Tab, Tabs } from "react-bootstrap";
import EMPTY_IMG from "../../../assets/images/empty.png";
import Moment from "react-moment";
const AdminApprovedComponent = ({ dbAccess }: any) => {
    const [key, setKey] = useState('personal_leave');
    const db: any = dbAccess;
    const [totalAp, setTotalAp] = useState(0);
    const [apList, setApList] = useState<LeaveModel[]>();
    const [totalApSick, setTotalApSick] = useState(0);
    const [apSickList, setSickApList] = useState<LeaveModel[]>();
    const getAwaitingList = async () => {
        const q = query(collection(db, "leave"), where("statusleave", "==", configLanguage.approve),
            where("leavetype", "==", configLanguage.personal_leave),
            where("approveDateCreate", ">=", new Date("01/01/" + config.currentYear)),
            orderBy("approveDateCreate", "desc"));
        const querySnapshot = await getDocs(q);
        const leavetd: LeaveModel[] = [];
        querySnapshot.forEach((doc) => {
            const dataRes = doc.data() as LeaveModel;
            console.log(doc.data());
            leavetd.push(dataRes);
        });
        setTotalAp(leavetd.length);
        setApList(leavetd)
        getAwaitingSickList();
    }

    const getAwaitingSickList = async () => {
        const q = query(collection(db, "leave"), where("statusleave", "==", configLanguage.approve),
            where("leavetype", "==", configLanguage.sick_leave),
            where("approveDateCreate", ">=", new Date("01/01/" + config.currentYear)),
            orderBy("approveDateCreate", "desc"));
        const querySnapshot = await getDocs(q);
        const leavetd: LeaveModel[] = [];
        querySnapshot.forEach((doc) => {
            const dataRes = doc.data() as LeaveModel;
          
            leavetd.push(dataRes);
        });
        setTotalApSick(leavetd.length);
        setSickApList(leavetd)


    }

    React.useEffect(() => {
        getAwaitingList();

    }, [])
    return (
        <>
            <div className="admin-approved">
                <Tabs
                    activeKey={key}
                    onSelect={(k: any) => setKey(k)}
                    className="tabs-control"
                    fill
                >
                    <Tab eventKey="personal_leave" title={`Personal Leave (${totalAp})`}>
                        {
                            apList?.length === 0 ?

                                <div className="d-flex flex-column">
                                    <img src={EMPTY_IMG} className="not-found-img mx-auto"></img>
                                    <span className="not-found-text">ไม่มีรายการ</span>
                                </div> :

                                <div className="d-flex flex-column">

                                    <div className="info-control">
                                        <div className="info-row" >
                                            <span className="text-desc-bold my-auto">No.</span>
                                            <span className="text-desc-bold my-auto">Name</span>
                                            <span className="text-desc-bold my-auto mx-auto">Approved Date</span>
                                            <span className="text-desc-bold my-auto mx-auto">Time</span>
                                            <span className="text-desc-bold my-auto mx-auto">Who approved</span>
                                        </div>
                                    </div>
                                    <div className="info-control">
                                        {
                                            apList?.map((item: LeaveModel, index: number) => {

                                                return (
                                                    <div className="info-row" key={index}>
                                                        <span className="text-desc my-auto">{index + 1}</span>
                                                        <div className="detail-control  my-auto">
                                                            <span className="text-desc-bold">
                                                                {item.employeenickname}
                                                            </span>
                                                            <span className="text-small">(
                                                                {
                                                                    item.employeename
                                                                })
                                                            </span>
                                                        </div>
                                                        <span className="text-desc text-center">

                                                            {
                                                                item.dateleavefrom.toDate().toDateString() === item.dateleaveto.toDate().toDateString() ?

                                                                    <Moment format="DD-MMM-YYYY">
                                                                        {item.dateleavefrom.toDate()}
                                                                    </Moment> :
                                                                    <>
                                                                        <Moment format="DD-MMM-YYYY">
                                                                            {item.dateleavefrom.toDate()}
                                                                        </Moment> -   <Moment format="DD-MMM-YYYY">
                                                                            {item.dateleaveto.toDate()}
                                                                        </Moment>
                                                                    </>
                                                            }

                                                        </span>
                                                        <span className="text-desc text-center">
                                                            {item.leavetime}
                                                        </span>
                                                        <span className="text-desc text-center">
                                                            {item.action_by ? item.action_by.toString().split('@scoutout.net') : "-"}
                                                        </span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                        }

                    </Tab>
                    <Tab eventKey="sick_leave" title={`Sick Leave (${totalApSick})`}>
                        {
                            apSickList?.length === 0 ?

                                <div className="d-flex flex-column">
                                    <img src={EMPTY_IMG} className="not-found-img mx-auto"></img>
                                    <span className="not-found-text">ไม่มีรายการ</span>
                                </div> :
                                <div className="d-flex flex-column">
                                    <div className="info-control">
                                        <div className="info-row" >
                                            <span className="text-desc-bold my-auto">No.</span>
                                            <span className="text-desc-bold my-auto">Name</span>
                                            <span className="text-desc-bold my-auto mx-auto">Leave Date</span>
                                            <span className="text-desc-bold my-auto mx-auto">Reason</span>
                                        </div>
                                    </div>
                                    <div className="info-control">
                                        {
                                            apSickList?.map((item2: LeaveModel, index2: number) => {
                                                return (
                                                    <div className="info-row" key={index2}>
                                                        <span className="text-desc my-auto">{index2 + 1}</span>
                                                        <div className="detail-control  my-auto">
                                                            <span className="text-desc-bold">
                                                                {item2.employeenickname}
                                                            </span>
                                                            <span className="text-small">(
                                                                {
                                                                    item2.employeename
                                                                })
                                                            </span>
                                                        </div>
                                                        <span className="text-desc text-center">
                                                        {
                                                                item2.dateleavefrom.toDate().toDateString() === item2.dateleaveto.toDate().toDateString() ?

                                                                    <Moment format="DD-MMM-YYYY">
                                                                        {item2.dateleavefrom.toDate()}
                                                                    </Moment> :
                                                                    <>
                                                                        <Moment format="DD-MMM-YYYY">
                                                                            {item2.dateleavefrom.toDate()}
                                                                        </Moment> -   <Moment format="DD-MMM-YYYY">
                                                                            {item2.dateleaveto.toDate()}
                                                                        </Moment>
                                                                    </>
                                                            }
                                                        </span>
                                                        <span className="text-desc text-center">
                                                            {item2.des}
                                                        </span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>}
                    </Tab>

                </Tabs>

            </div>
        </>
    )


}

export default AdminApprovedComponent;