import { initializeApp } from "firebase/app";
import { collection, doc, getDoc, getDocs, getFirestore, orderBy, query, Timestamp, where } from "firebase/firestore";
import React, { useState } from "react";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import config from "../../../config";
import configLanguage from "../../../config-language";
import LeaveModel from "../../../model/leave.model";
import ProfileModel from "../../../model/profile.model";
import "./admin-report.scss";
import moment from 'moment';
import ApiServices from "../../../services/api.service";
const AdminReportComponent = ({ props }: any) => {
    const { id } = useParams();
    const app = initializeApp(config.firebaseConfig);
    const db = getFirestore(app);
    const [usePsnLeave, setUsePsnLeave] = useState(0);
    const [useRemainLastPsnLeave, setUseRemainLastPsnLeave] = useState(0);
    const [remainPsnLeave, setRemainPsnLeave] = useState(0);
    const [useSickLeave, setUseSickLeave] = useState(0);
    const [remainSickLeave, setRemainSickLeave] = useState(0);
    const [profile, setProfile] = useState<ProfileModel>();
    const [useOnlyDefaultLeave, setUseOnlyDefaultLeave] = useState(0);
    const [allLeave, setAllLeave] = useState<LeaveModel[]>();
    const checkLeave = async (profile: ProfileModel) => {
        const q = query(collection(db, "leave"), where("dateleavefrom", ">=", new Date("01/01/"+config.currentYear)),
            where("dateleavefrom", "<=", new Date("01/01/" + (config.nextYear) )),
            where("statusleave", "==", "Approved")
            , where("useruid", "==", id));
        const querySnapshot = await getDocs(q);
        const leaveme: LeaveModel[] = [];
        let personalLeave = 0;
        let useRemainLastYear = 0;
        let isUseRemainLastYearFull = false;
        let useOnlyDFLeave = 0;
        let overRemainTotal = 0;
        let sickleave = 0;
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            const dataRes = doc.data() as LeaveModel;
            dataRes.docId = doc.id;
            if (dataRes.leavetype === configLanguage.personal_leave) {

                if (new Date(dataRes.datecreate.toDate()) < new Date("05/01/"+ config.currentYear)) {


                    useRemainLastYear += dataRes.leavedaytotal;
                    if(isUseRemainLastYearFull){
                        dataRes.useAs = "Normal";
                    }else{
                        console.log("Remain")
                        dataRes.useAs = "Remain";
                    }
                    if (useRemainLastYear >= profile.lastYearRemainDay) {
                        isUseRemainLastYearFull = true;
                        overRemainTotal = useRemainLastYear - profile.lastYearRemainDay;
                        useRemainLastYear = profile.lastYearRemainDay;
                    } else {
                        if (!isUseRemainLastYearFull) {
                        }else{
                            useOnlyDFLeave = useOnlyDFLeave + dataRes.leavedaytotal;
                           
                            console.log("useOnlyDFLeave : " + useOnlyDFLeave)
                        }
                    }
                    console.log("useRemainLastYear: " + useRemainLastYear)
                    personalLeave = personalLeave + dataRes.leavedaytotal;
                   
                } else if (new Date(dataRes.datecreate.toDate()) > new Date("05/01/" + config.currentYear)) {
                    useOnlyDFLeave = useOnlyDFLeave + dataRes.leavedaytotal;
                    personalLeave = personalLeave + dataRes.leavedaytotal;
                }
                
                console.log(dataRes.leavedaytotal + " === " + dataRes.datecreate.toDate().toDateString());

            } else {
                sickleave += dataRes.leavedaytotal;
                dataRes.useAs = "Sick";
            }

            leaveme.push(dataRes);
            setAllLeave(leaveme);
        });


        const remainSickLeave = profile.totalSickLeave - sickleave;
        if (useRemainLastYear >= profile.lastYearRemainDay) {
            const remainLeave = (profile.totalPersonalLeave + profile.lastYearRemainDay) - (personalLeave);

            setUsePsnLeave(personalLeave - useRemainLastYear);
            setRemainPsnLeave(remainLeave);
            const updateProfile = {
                remainPersonalLeave: remainLeave,
                remainSickLeave: remainSickLeave
            }

        } else {

            if (new Date() >= new Date("05/01/" + config.currentYear)) {
                console.log(useRemainLastYear);
                const remainLeave = (profile.totalPersonalLeave + useRemainLastYear) - (useOnlyDFLeave + useRemainLastYear);
                setUsePsnLeave(personalLeave - useRemainLastYear);
                setRemainPsnLeave(remainLeave);
                const updateProfile = {
                    remainPersonalLeave: remainLeave,
                    remainSickLeave: remainSickLeave
                }
            } else {
                console.log(useOnlyDFLeave);
                const remainLeave = (profile.totalPersonalLeave + profile.lastYearRemainDay) - (useOnlyDFLeave + useRemainLastYear);
                setUsePsnLeave(personalLeave - useRemainLastYear);
                setRemainPsnLeave(remainLeave);
                const updateProfile = {
                    remainPersonalLeave: remainLeave,
                    remainSickLeave: remainSickLeave
                }
            }


        }
        setUseOnlyDefaultLeave(personalLeave - useRemainLastYear)
        setUseRemainLastPsnLeave(useRemainLastYear)
        setUseSickLeave(sickleave);
        setRemainSickLeave(remainSickLeave);

    }

    const getUsersData = async (uid: any) => {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const dataRes = docSnap.data() as ProfileModel;
            console.log(dataRes);
            setProfile(dataRes);
            checkLeave(dataRes)
            console.log("Document data:", docSnap.data());
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }


    }
    React.useEffect(() => {
            getUsersData(id)

    }, [])

    const print = () => {


        window.print();
    }
    const calExp = (probDate: Timestamp, id: string) => {

        const proDate = new Date(probDate.toDate()) as Date;
        const dateCurrent = new Date;
        const currentDateDif = moment(dateCurrent);
        const prorateDateDif = moment(proDate);

        const yearPro = currentDateDif.diff(prorateDateDif, 'year');
        prorateDateDif.add(yearPro, 'years');
        const months = currentDateDif.diff(prorateDateDif, 'months');
        prorateDateDif.add(months, 'months');
        const days = currentDateDif.diff(prorateDateDif, 'days');
        prorateDateDif.add(days, 'days');

        const showYears = yearPro + 0;
        const showMonth = months + 0;
        const showDays = days + 0;
        // console.log(showYears + " Years " + showMonth + " Months " + showDays + " Day");

        // if(id === "6SspkdBbLoQMnjvyfqJMcZgeM0b2"){
        //     ApiServices().updatePersonalLeave(id, config.personalLeave + showYears)
        // }
        let newTotal = config.personalLeave + showYears;
        if (newTotal > 20) {
            newTotal = 20;
        }

        return showYears + " Years " + showMonth + " Months " + showDays + " Day";

    }

    const saveReport = () => {
        const nameOfReport =  "report_"+config.currentYear;
      
        const reportUpdate = {};
        if(profile){
            reportUpdate[nameOfReport] = {
                "used" : useOnlyDefaultLeave,
                "remain" : remainPsnLeave,
                "sicked" : useSickLeave
            }
            console.log(reportUpdate);
        }

        // ApiServices().updateProfile(id,reportUpdate)
       
    }

    return (
        <>

            <div className="container d-flex flex-column admin-report">
                {
                    profile &&
                    <div className="d-flex flex-column">
                        <span className="admin-report-who-text">{profile.displayName}
                        <span className="admin-report-who-text-sub"> ({profile.employeenickname})</span></span>
                        <span className="admin-report-who-text">Contact: 
                        <span className="admin-report-who-text-sub"> {profile.phone}</span></span>
                        <span className="admin-report-who-text">Team:
                            <span className="admin-report-who-text-sub"> {profile.team}</span></span>
                        <span className="admin-report-who-text">Role:
                            <span className="admin-report-who-text-sub"> {profile.position.map((item: string, index: number) => {


                                return (
                                    index === profile?.position.length - 1 ?
                                        <span key={index}>{item}</span> : <span key={index}>{item}, </span>
                                )
                            })}</span></span>
                        <span className="admin-report-who-text">Start working date:

                            <Moment format=" DD-MMM-YYYY" className="admin-report-who-text-sub">
                                {profile.startDate.toDate()}
                            </Moment></span>
                        <span className="admin-report-who-text">Probation date:

                            <Moment format=" DD-MMM-YYYY" className="admin-report-who-text-sub">
                                {profile.probationDate.toDate()}
                            </Moment></span>
                        <span className="admin-report-who-text">Experience in ScoutOut:
                            <span className="admin-report-who-text-sub"> {calExp(profile.probationDate, profile.uid).toString()}</span></span>
                    </div>
                }

                <div className="summary-report-control d-flex flex-column">
                    <h1 className="summary-topic">Summary:</h1>
                    <div className="divider-line"></div>
                    <div className="summary-grid-control">
                        <div className="summary-grid-item">
                            <span className="summary-topic">{configLanguage.personal_leave}</span>
                            <div className="grid-display">
                                <span className="summary-grid-normal">This year remain ({config.currentYear})</span>
                                <span className="summary-grid-normal">{profile?.totalPersonalLeave}</span>
                            </div>
                            <div className="grid-display">
                                <span className="summary-grid-normal">Last year remain ({config.lastYear})</span>
                                <span className="summary-grid-normal">{profile?.lastYearRemainDay}</span>
                            </div>
                            <div className="grid-display">
                                <span className="summary-grid-normal">Used last year ({config.lastYear}) remain</span>
                                <span className="summary-grid-normal">-{useRemainLastPsnLeave}</span>
                            </div>
                            <div className="grid-display">
                                <span className="summary-grid-normal">Used this year ({config.currentYear}) remain</span>
                                <span className="summary-grid-normal">-{useOnlyDefaultLeave}</span>
                            </div>
                            <div className="grid-display">
                                <span className="summary-grid-header">Total Remain</span>
                                <span className="summary-grid-value">{remainPsnLeave} day(s)</span>
                            </div>
                            <div className="divider-line"></div>
                        </div>
                        <div className="summary-grid-item-right">
                            <span className="summary-topic">{configLanguage.sick_leave}</span>
                            <div className="grid-display">
                                <span className="summary-grid-normal">This year Sick leave</span>
                                <span className="summary-grid-normal">{profile?.totalSickLeave}</span>
                            </div>
                            <div className="grid-display">
                                <span className="summary-grid-normal">Used</span>
                                <span className="summary-grid-normal">{useSickLeave}</span>
                            </div>
                            <div className="grid-display">
                                <span className="summary-grid-header">Total Remain</span>
                                <span className="summary-grid-value">{remainSickLeave} day(s)</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="admin-report-grid-control">
                    <span className="admin-report-grid-header my-auto">No.</span>
                    <span className="admin-report-grid-header my-auto">Type</span>
                    <span className="admin-report-grid-header my-auto">Date Created</span>
                    <span className="admin-report-grid-header my-auto">Date Leave</span>
                    <span className="admin-report-grid-header my-auto">Total Day(s)</span>
                    <span className="admin-report-grid-header my-auto">Status</span>
                </div>

                {
                    allLeave &&
                    allLeave.map((item: LeaveModel, index: any) => {


                        return (
                            <div className={
                                item.useAs === "Remain" ? "admin-report-grid-control my-auto bg-remain" :
                                    item.useAs === "Sick" ? "admin-report-grid-control my-auto bg-sick" :
                                        "admin-report-grid-control my-auto"
                            } key={index}>
                                <span className="admin-report-grid-desc my-auto">{index + 1}</span>
                                <span className="admin-report-grid-desc my-auto">{item.leavetype}</span>
                                <span className="admin-report-grid-desc my-auto">
                                    <Moment format="DD MMM YYYY" className="text-info-leave my-auto">
                                        {item.datecreate.toDate()}
                                    </Moment>
                                </span>
                                <span className="admin-report-grid-desc my-auto">  <Moment format="DD MMM YYYY" className="text-info-leave my-auto">
                                    {item.dateleavefrom.toDate()}
                                </Moment> - <Moment format="DD MMM YYYY" className="text-info-leave my-auto">
                                        {item.dateleaveto.toDate()}
                                    </Moment></span>
                                <span className="admin-report-grid-desc my-auto">{item.leavedaytotal}</span>
                                <span className="admin-report-grid-desc my-auto">{item.useAs === "Remain" ? "Last year" : item.useAs}</span>
                            </div>
                        )
                    })
                }
                <button className="button-print" onClick={print}>Print</button>
                <button className="button-print" onClick={saveReport}>Save Report</button>

            </div>


        </>
    )

}

export default AdminReportComponent;