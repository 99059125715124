

import config from "../config"
import { addDoc, collection, doc, getDocs, getFirestore, orderBy, query, updateDoc, where } from "firebase/firestore";
import { initializeApp } from "firebase/app";
export default function ApiServices() {

    return {
        async postAnswerID(answer_id: any) {
            const objectResult = {answer_id: answer_id}
            return fetch(config.api.url_quiz + config.api.postResult, {
                method: 'POST', headers: {
                    'Content-Type': 'text/plain',
                    'x-api-key': config.api.x_api_key_quiz,
                },
                body: JSON.stringify(objectResult)
            }
            ).then(res =>  res.json()).catch( err => console.log(err))
        },

        async getSimilarPersonality(uid: string){
            var params = {uid:uid}
            return fetch(config.api.url + config.api.getSimilarPersonality + "?"+new URLSearchParams(params), {
                method: 'GET', headers: {
                    'Content-Type': 'text/plain',
                    'x-api-key': config.api.x_api_key_quiz,
                }
            }
            ).then(res =>  res.json()).catch( err => console.log(err))
        },
        async getResultByType(type:string) {
            var params = {type:type, language:"th"}
            return fetch(config.api.url_quiz + config.api.result_type + "?"+new URLSearchParams(params), {
                method: 'GET', headers: {
                    'Content-Type': 'text/plain',
                    'x-api-key': config.api.x_api_key_quiz,
                }
            }
            ).then(res =>  res.json()).catch( err => console.log(err))
        },
        async saveHolidays(holidaysSubmit: any) {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);

            return addDoc(collection(db, "holidays"), holidaysSubmit)
        },

        async getHolidays() {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
            const q = query(collection(db, "holidays"),
            where("date" , ">=" , new Date("01/01/" + config.currentYear)),
            where("date" , "<=" , new Date("12/31/" + config.currentYear)),
            orderBy("date", "asc"))
            return getDocs(q);
        },

        async getCourses(jobRole: string, limit: number) {
            return fetch(config.api.url+ config.api.getCourse + "?jobRoles=" + jobRole + "&&limit="+limit, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))

        },

        async getPositions() {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
            const q = query(collection(db, "position"), orderBy("value", "asc"))
            return getDocs(q);
        },

        async saveLeave(leaveSubmit: any) {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);

            return addDoc(collection(db, "leave"), leaveSubmit)
        },

        async updateSyncLeaveCalendar(uid: any, totalLeaveObject: any) {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
            const washingtonRef = doc(db, "leave", uid);
            return updateDoc(washingtonRef, totalLeaveObject)
        },
        async updateSyncLeave(uid: any, totalLeaveObject: any) {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
            const washingtonRef = doc(db, "users", uid);
            return updateDoc(washingtonRef, totalLeaveObject)
        },

        async updateOCEAN(uid: any, oceanObject: any) {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
            const washingtonRef = doc(db, "users", uid);
            return updateDoc(washingtonRef, oceanObject)
        },

        async updateAllProfile(oceanObject: any){
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
            const q = query(collection(db, "users"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((docs) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                const washingtonRef = doc(db, "users", docs.id);
                return updateDoc(washingtonRef, oceanObject)

            });
        },
        async resetAllEmployees(uid: any) {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
            const washingtonRef = doc(db, "users", uid);
            return updateDoc(washingtonRef, {
                remainPersonalLeave: 0
            })
        },

        async approveLeave(uid: any, objApprove: any) {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
            const washingtonRef = doc(db, "leave", uid);

            return updateDoc(washingtonRef, objApprove)
        },

        async updatePersonalLeave(uid: any, totalLeave: number) {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
            const washingtonRef = doc(db, "users", uid);
            return updateDoc(washingtonRef, {
                totalPersonalLeave: totalLeave,
                totalSickLeave: config.sickLeave
            })
        },

        async updateProfile(uid: any, objectRes: any) {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
            const washingtonRef = doc(db, "users", uid);
            return updateDoc(washingtonRef, objectRes)
        },

        async deleteLeave(id: any) {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
            const washingtonRef = doc(db, "leave", id);
            return updateDoc(washingtonRef, {
                statusleave: "Canceled"
            })
        },
    }
}